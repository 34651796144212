<template>
  <template v-if="$page">
    <div class="new-header">
      <div class="new-header__image" :style="{backgroundImage : `url(${$page.header.image.lg || $page.header.image.original})`}"/>

      <div class="new-header__content">
        <h1 class="header__title" v-scroll-reveal>{{ $page.header.title }}</h1>
        <p class="header__text" v-scroll-reveal>{{ $page.header.subtitle }}</p>
      </div>
    </div>

      <PageBuilder :sections="$page.sections"/>


    <!--

    <SectionSplit
        reverse
        :title="$page.section1.title"
        :text="$page.section1.text"
        :image="$page.section1.image"
        :button-label="$page.section1.buttonLabel"
        :button-action="() => $router.push(getLocalRoute({name:'program'}))"

    />

    <SectionSplit
        :title="$page.section2.title"
        :text="$page.section2.text"
        :image="$page.section2.image"
    />

    <SectionTimeline :title="$page.history.title"/>

    <SectionGiants/>

    <SectionCta
        light
        :title="$page.costumes.title"
        icon="birds"
        :text="$page.costumes.text"
    />

    <SectionVetement :title="$page.clothes.title"/>
    -->
  </template>
  <template v-else>
    <div style="min-height: 100vh"/>
  </template>


</template>

<script>

import {setMeta} from "@/helpers/metaHelpers";
import SectionSplit from "@/components/SectionSplit";
import SectionCta from "@/components/SectionCta";
import SectionTimeline from "@/components/SectionTimeline";
import SectionGiants from "@/components/SectionGiants";
import SectionVetement from "@/components/SectionVetement";
import PageBuilder from "@/components/PageBuilder.vue";

export default {
  name: 'Fete',
  components: {PageBuilder, SectionVetement, SectionGiants, SectionTimeline, SectionCta, SectionSplit},
  beetPage: 'fetes',
  beforeMount() {
    if(this.$page) setMeta(this.$page.seo)
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    }
  }
}
</script>
