<template>
  <template v-if="event">
    <div class="new-header">
      <div class="new-header__image" :style="{backgroundImage : `url(${event.image?.lg || event.image?.original})`}"/>
      <div class="new-header__content ">
        <div>
          <LangRouterLink :to="{name: 'programmation'}" class="row header__back">
            <icon id="back"/>
            {{ $t('backToProg') }}
          </LangRouterLink>
          <h1 class="header__title" style="max-width: 60%">{{ event.name }}</h1>
        </div>

        <div class="header__infos">
          <div class="header__infos-row" @click="goToMap(event.geolocation)">
            <Icon id="geo"/>
            <div class="header__infos-link">
              {{event.locationName}} <Icon id="link" style="margin-left: 0.5rem; font-size: 0.75rem"/>
            </div>
          </div>
          <div class="header__infos-row" v-if="getSiteDate">
            <Icon id="date"/> {{ getSiteDate }}
          </div>
          <div class="header__infos-row" v-else>
            <Icon id="date"/> {{ event.isAllFestival || site ? $t('allEvent') : event.times ? event.times : getDate(event.time) }}
          </div>
          <button class="button header__infos-button tpos-add-to-cart" v-if="event.lpvEventId" type="button" :data-tpos-event="event.lpvEventId"> {{$t('reserve')}} </button>
        </div>
      </div>
    </div>

    <div class="event__wrap">
      <div v-html="event.content" class="raw-html event"/>

      <div class="header__infos -desktop">
        <div class="header__infos-row" @click="goToMap(event.geolocation)">
          <Icon id="geo"/>
          <div class="header__infos-link">
            {{event.locationName}} <Icon id="link" style="margin-left: 0.5rem; font-size: 0.75rem"/>
          </div>
        </div>
        <div class="header__infos-row" v-if="getSiteDate">
          <Icon id="date"/> {{ getSiteDate }}
        </div>
        <div class="header__infos-row" v-else>
          <Icon id="date"/> {{ event.isAllFestival || site ? $t('allEvent') : event.times ? event.times : getDate(event.time) }}
        </div>
        <button class="button header__infos-button tpos-add-to-cart" v-if="event.lpvEventId" type="button" :data-tpos-event="event.lpvEventId"> {{$t('reserve')}} </button>
      </div>
    </div>

    <div class="section -left" v-if="event.partners?.length">
      <h2 class="event__partners-title"> {{ $t('partners') }} </h2>
      <div class="event__partners">
        <a v-for="(partnerId, key) in event.partners" :key="key" class="event__partners-logo" :href="getPartner(partnerId).url" target="_blank">
          <img :src="getPartner(partnerId).logo.xs || getPartner(partnerId).logo.original" alt="partner logo"/>

        </a>
      </div>
    </div>
  </template>
  <template v-else>
    <div style="min-height: 100vh"/>
  </template>

</template>

<script>
import LangRouterLink from "@/components/global/LangRouterLink";
export default {
  name: "Event",
  components: {LangRouterLink},
  watch: {
    event : {
      handler(to) {
        if(to) {
          const metas = document.getElementsByTagName('meta')
          document.title = `Fêtes de la Nouvelle-France TD - ${to.name}` || 'Fêtes de la Nouvelle-France TD';
          metas["og:title"].content = `Fêtes de la Nouvelle-France TD - ${to.name}` || 'Fêtes de la Nouvelle-France TD'
          metas["og:image"].content = to.img || '/img/logo.svg'
        }
      },
      immediate: true
    }
  },
  computed: {
    getSiteDate() {
      if(this.site && this.site.dates) return this.site.dates
      return null
    },
    event() {
      return this.site ? this.site : this.events
    },
    site() {
      if(this.$beet.isReady) {
        const arr = Object.values(this.$beet.sites)
        return arr.find(({slugs}) => {
          return (slugs.fr === this.$route.params.slug || slugs.en === this.$route.params.slug)
        })
      }
      return null
    },
    events() {
      if(this.$beet.isReady) {
        const arr = Object.values(this.$beet.events)
        return arr.find(({slugs}) => {
          return (slugs.fr === this.$route.params.slug || slugs.en === this.$route.params.slug)
        })
      }
      return null
    }
  },
  methods: {
    getPartner(partnerId){
      if(this.$beet.partners) {
        const arr = Object.values(this.$beet.partners)
        return arr.find(({id}) => id === Number(partnerId))
      }
    },
    goToMap(geo) {
      return window.open(`https://www.google.com/maps/dir/?api=1&destination=${geo.lat}, ${geo.long}`, {target: '_blank'})
    },
    getDate(date) {
      const day = new Date(date).toLocaleDateString(`${this.$i18n.locale}-CA`, {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      })

      const time = this.getHour(date)
      return day + ' - ' + time
    },
    getHour(date) {
      const time = new Date(date).toLocaleTimeString(`${this.$i18n.locale}-CA`, {
        hour: '2-digit',
        minute: '2-digit',
      });
      return time
    }
  },
}
</script>
