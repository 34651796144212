<template>
  <div class="footer__split" v-if="this.$beet.isReady">
    <div class="footer__split--form">
      <div class="footer__section-title" > {{ $beet.options.footer?.scoop.title }}</div>
      <p class="footer__section-text" >{{ $beet.options.footer?.scoop.text }}</p>
      <form @submit.prevent="post">
        <div class="row" >
          <label class="input -light">
            <span>{{ $t('firstName') }}</span>
            <input type="text" class="input__text" required v-model="form.firstName"/>
          </label>

          <label class="input -light">
            <span>{{ $t('lastName') }}</span>
            <input type="text" class="input__text" required v-model="form.lastName"/>
          </label>
        </div>
        <div class="row" >
          <label class="input -light">
            <span>{{ $t('email') }}</span>
            <input type="text" class="input__text" required v-model="form.email"/>
          </label>
        </div>
        <br/>
        <div class="row -end" >
          <button class="button -light">{{ this.$t('subscribe') }}</button>
        </div>
      </form>
    </div>

    <div class="footer__split--infos">
      <section class="footer__section -between">
        <div>
          <h4 class="footer__section-title" >{{ $beet.options.footer?.coordinate.title }}</h4>

          <div class="footer__section-row">
            <Icon id="geo"/>
            <div v-if="options.address">
              {{ options.address }}
            </div>
          </div>

          <div class="footer__section-row" v-if="options.phone">
            <Icon id="phone"/>
            {{ options.phone }}
          </div>

          <a :href="`mailto:${options.email}`" class="footer__section-row" >
            <Icon id="mail"/>
            {{ options.email }}
          </a>
        </div>


        <LangRouterLink :to="{name:'contact'}" class="button footer__section--button" >{{
            $t('contact')
          }}
        </LangRouterLink>
      </section>

      <section class="footer__section -end">
        <h4 class="footer__section-title">{{ $t('knowMore') }}</h4>
        <LangRouterLink
            v-for="link in links"
            :to="{name: link.name, params: link.params}"
            :key="link.label"
            class="footer__section-row" >
          {{ link.label }}
        </LangRouterLink>
        <LangRouterLink :to="{name:'info', hash: '#faq'}" class="footer__section-row" >{{
            $t('links.faq')
          }}
        </LangRouterLink>
      </section>
    </div>
  </div>

  <div class="footer">
    <div class="footer__flex">
      <div class="footer__text" v-if="this.$beet.options.footer">
        {{ $beet.options.footer.thanks }}
      </div>
      <div class="footer__logos" >
        <img v-for="partner in partners" :key="partner.flexKey"  :src="partner.logo.original" class="footer__logo -small" @click="goToLink(partner.url)"/>
      </div>
    </div>
    <div class="footer__privacy">
      <p>{{ $t('privacy.text') }} - <a href="https://boitebeet.com/" target="_blank">{{ $t('privacy.beet') }}</a></p>
      <lang-router-link :to="{ name: 'privacy' }" class="footer__privacy-link">{{$t('privacy.link') }}
      </lang-router-link>
    </div>
  </div>
</template>

<script>
import LangRouterLink from "@/components/global/LangRouterLink";

export default {
  name: "SiteFooter",
  components: {LangRouterLink},
  data() {
    return {
      form: {
        lang: this.$i18n.locale,
        firstName: '',
        lastName: '',
        email: ''
      },
    }
  },
  computed: {
    partners() {
      return this.$beet.options?.footer?.partners || []
    },
    options() {
      return {
        address: this.$beet.options.contact?.address || '',
        phone: this.$beet.options.contact?.phone || '',
        email: this.$beet.options.contact?.email || ''
      }
    },
    links() {
      if (this.$beet.options.menu && this.$store.state.pages) {
        const links = []
        const routes = this.$router.getRoutes()
        let routeParams = {}

        this.$beet.options.navigation.entries.forEach(entry => {
          let routeName = this.$store.state.pages[Number(entry.page)].key
          console.log(routeName, entry.page)
          if (!routes.some(({name}) => name === routeName)) {
            routeName = 'page'
            routeParams = {slug: this.$store.state.pages[Number(entry.page)].slugs[this.$i18n.locale]}

          }

          links.push({
            name: routeName,
            label: entry.name,
            params: routeParams
          })
        })
        return links
      }
      return []
    }
  },
  methods: {
    goToLink(link) {
      window.open(link, '_blank')
    },
    showRecaptcha() {
      document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'visible'
    },
    post() {
      this.$recaptcha('contact').then(() => {
        if (this.form.email.includes('@')) {
          this.$store.dispatch('newsletter', this.form).then(() => {
            this.form = {
              lang: this.$i18n.locale,
              firstName: '',
              lastName: '',
              email: ''
            }
            setTimeout(() => {
              document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'hidden'
            }, 5000)
          }).catch((err) => {
            console.log(err)
          })
        }
      })
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.showRecaptcha()
      }
    }
  }
}
</script>
