
<template>
  <section class="section">
    <div class="video" v-scroll-reveal>
      <div class="video__placeholder" @click="video = true" :style="{backgroundImage: `url(${thumbnail.lg || thumbnail.original})`}">
        <transition name="fade">
          <iframe
              v-if="video"
              :src="link"
              frameborder="0"
              allowfullscreen
              allow="autoplay"
              class="video__placeholder-video"
          />
          <img src="/img/video.svg" class="video__placeholder-icon" v-else/>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionVideo",
  data() {
    return {
      video: false
    }
  },
  props: {
    link: {
      type: String,
      default: 'https://www.youtube.com/embed/rk5SGQR8HJM?autoplay=1'
    },
    thumbnail: {
      type: Object
    }
  }
}
</script>