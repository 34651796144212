import {createRouter, createWebHistory} from 'vue-router'
import {Home, Tickets, NotFound, Contact, Fete, Page, Info, Media, MediaPress, Program, Event, Team, Words, Privacy} from '@/views'
import { generateRoutes } from '@boite-beet/lang-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/fete',
        name: 'fetes',
        component: Fete
    },
    {
        path: '/info',
        name: 'info',
        component: Info
    },
    {
        path: '/billetterie',
        name: 'tickets',
        component: Tickets
    },
    {
        path: '/medias',
        name: 'medias',
        component: Media
    },
    {
        path: '/medias/:slug',
        name: 'mediaPress',
        component: MediaPress
    },
    {
        path: '/programmation',
        name: 'programmation',
        component: Program
    },
    {
        path: '/programmation/:slug',
        name: 'event',
        component: Event
    },
    {
        path: '/sites/:slug',
        name: 'site',
        component: Event
    },
    {
        path: '/equipe',
        name: 'team',
        component: Team
    },
    {
        path: '/dignitaire',
        name: 'dignitaire',
        component: Words
    },
    {
        path: '/confidentialite',
        name: 'privacy',
        component: Privacy
    },
    {
        path: '/:slug',
        name: 'page',
        component: Page
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes
})

export default router
