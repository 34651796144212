<template>
  <section class="program-events" id="events">
    <h2 class="program-intro__title" v-scroll-reveal>{{ title }}</h2>
    <p class="program-intro__text" v-scroll-reveal>{{ description }}</p>

    <div class="program-events__list">
      <LangRouterLink :to="{name:'event', params: {slug: event.slugs[$i18n.locale]}}" class="program-events__card" v-for="(event, key) in punctual" :key="key" v-scroll-reveal>
        <div class="program-events__card-img" :style="{backgroundImage:`url('${event.image?.md || event.image?.original}')`}"/>

        <div class="program-events__card-content">
          <div>
            <div class="program-events__card-title">{{event.name}}</div>

            <div class="program-events__card-row">
              <Icon id="geo"/>
              {{event.locationName}}
            </div>

            <div class="program-events__card-row">
              <Icon id="date"/>
              {{event.isAllFestival ? $t('allEvent') : event.times ? event.times : getDate(event.time) }}
            </div>
          </div>

          <div class="program-events__card-link-container">
            <div class="program-events__card-link">
              <Icon id="arrow"/>
            </div>
          </div>
        </div>
      </LangRouterLink>
    </div>
  </section>
</template>


<script>
export default {
  name: "SectionEvents",
  props: {
    title: {type: String},
    description: {type: String},
    is_hunt: {type: Boolean},
    background_color: {type: String}
  },
  computed: {
    punctual() {
      if (this.$beet.isReady) {
        const arr = Object.values(this.$beet.events)
        return arr.sort((a, b) => {
          if (a.order < b.order) return -1
          if (a.order > b.order) return 1
          return 0
        })
      }
      return []
    },
    methods: {
      getDate(date) {
        const day = new Date(date).toLocaleDateString(`${this.$i18n.locale}-CA`, {
          weekday: 'long',
          month: 'long',
          day: 'numeric'
        })
        return day
      },
      getHour(date) {
        const time = new Date(date).toLocaleTimeString(`${this.$i18n.locale}-CA`, {
          hour: '2-digit',
          minute: '2-digit',
        });
        return time
      }
    }
  }
}
</script>

