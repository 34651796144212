<template>
  <div class="media-press -full-height" v-if="$page">
    <h1 class="section__title">{{ $page.title }}</h1>
    <raw-html :html="$page.content"/>
  </div>
  <div v-else style="min-height: 100vh"/>
</template>

<script>
import {setMeta} from "@/helpers/metaHelpers";

export default {
  name: "Privacy",
  beetPage: "privacy",
  beforeMount() {
    if(this.$page) setMeta(this.$page.seo)
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    }
  },
}
</script>

<style scoped>

</style>