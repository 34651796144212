<template>
  <template v-if="$page">
    <div class="new-header">
      <div class="new-header__image" :style="{backgroundImage : `url(${$page.header.image.lg || $page.header.image.original})`}"/>

      <div class="new-header__content">
        <h1 class="header__title">{{ $page.header.title }}</h1>
        <p class="header__text">{{ $page.header.subtitle }}</p>
      </div>
    </div>


    <section class="section">
      <h2 class="section__title">{{ $page.corpo.title }}</h2>
      <p class="section__text" v-html="$page.corpo.subtitle"></p>

      <div class="team">
        <div v-for="(member, key) in members" :key="key" class="team__item">
          <b class="team__title">{{member.name}} - {{member.fnfTitle}}</b>
          <p class="team__text">{{member.business ? `${member.business},` : ''}} {{member.jobTitle}}</p>
        </div>
      </div>
    </section>

    <PageBuilder :sections="$page.sections"/>

<!--
    <SectionLinks
        :title="$page.associations.title"
        :text="$page.associations.text"
        :links="associations"
    />

    <SectionSplit
        :title="$page.partners.title"
        :text="$page.partners.text"
        :image="$page.partners.image"
    />

    <section class="section" v-for="(partner, index) in partners" :key="index">
      <h3 class="section__title">{{partner.name}}</h3>
      <div class="info-cards">
        <div class="card-link info-cards__card" v-for="(card, key) in partner.items" :key="key">
          <div class="card-link__img" :style="{backgroundImage: `url(${card.logo})`}"/>
          <a :href="card.url" target="_blank" class="button -transparent" style="padding: 0.75rem 0.25rem;">
            {{card.name}}
            <Icon id="link"/>
          </a>
        </div>
      </div>
    </section>
    -->
  </template>

  <template v-else>
    <div style="min-height: 100vh"/>
  </template>

</template>

<script>

import {setMeta} from "@/helpers/metaHelpers";
import SectionLinks from "@/components/SectionLinks";
import SectionSplit from "@/components/SectionSplit";
import PageBuilder from "@/components/PageBuilder.vue";

export default {
  name: 'Team',
  components: {PageBuilder, SectionSplit, SectionLinks},
  beetPage: 'team',
  beforeMount() {
    if(this.$page) setMeta(this.$page.seo)
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    }
  },
  computed: {
    members() {
      if(!this.$beet.isReady) return []
      return this.$beet.members
    },

    associations() {
      let assoc = []
      if(!this.$beet.isReady) return assoc
      const types = Object.values(this.$beet.partnerTypes)
      const type = types.find(({key}) => key === 'assoc')
      if(type) {
        assoc = this.$beet.partners.filter(({partnerTypeId}) => partnerTypeId === type.id)
        assoc.forEach(item => {
          item.img = item.image.lg || item.image.original
          item.label = item.buttonLabel
        })
      }

      return assoc
    },

    partners() {
      if(!this.$beet.isReady) return []
      const types = Object.values(this.$beet.partnerTypes)
      const partners = []
      types.forEach(type => {
        if(!type.key) {
          const partner = type
          partner.items = this.$beet.partners.filter(({partnerTypeId}) => partnerTypeId === type.id)
          partners.push(partner)
        }
      })
      return partners.sort((a,b) => {
        if(a.order < b.order) return -1
        if(a.order > b.order) return 1
        return 0
      })
    }
  }
}
</script>
