export default {
    partners: 'Partenaires',
    hello: 'Bonjour',
    contact: 'Contactez-nous',
    subscribe: 'Inscrivez-vous',
    reserve: 'Réservez votre place',
    thanks: 'Merci',
    visit: 'Visitez le site',
    sanitary: 'Consultez les règles sanitaires',
    medalTitle: 'Participez aux Fêtes de la Nouvelle-France TD',
    medalText: 'Procurez-vous le Médaillon-décrypteur',
    participateTitle: 'Participez à la Chasse au trésor TD',
    participateText: 'Obtenir plus de détails sur la Chasse',
    allEvent: 'Tout au long du festival',
    backToProg: 'Retour à la programmation',
    buyRoyal: 'Acheter le Forfait Royal',
    knowMore: 'En savoir plus',
    seeMore: 'Voir plus',
    histories: 'd\'histoire',
    giant: 'de géant',
    clothes: 'de vêtements',
    mediaBack: 'Retour aux communiqués',
    classicbox: 'La Classique',
    vegebox: 'La Végé',
    carnaval: 'Notez que l’événement est géré par le',
    personalInfo: "Marie-Eve Jacob, responsable de la protection des renseignements personnels",
    concour: {
        title: 'Pour le 25e anniversaire des Fêtes de la Nouvelle-France TD, gagne une soirée VIP Chez Justine !',
        text: 'Dans le cadre du 25e anniversaire des Fêtes de la Nouvelle-France TD, gagne un certificat-cadeau pour une soirée VIP pour toi et 4 de tes ami(e)s au bar et restaurant d’ambiance speakeasy Chez Justine situé en plein cœur du Vieux-Québec.',
        button: 'Participez au concours'
    },
    links: {
        home: 'Accueil',
        program: 'Programmation',
        fete: 'Les Fêtes',
        info: 'Informations pratiques',
        contact: 'Contact',
        team: 'Équipe et partenaires',
        media: 'Médias',
        words: 'Mots des dignitaires',
        faq: 'Foire aux questions',
        tickets: 'Billetterie'

    },
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Adresse courriel',
    phone: 'Téléphone',
    subject: 'Sujet',
    goal: 'But de la demande d’accréditation',
    send: 'Envoyez le message',
    submit: 'Soumettez la demande',
    sent: 'Votre message a bien été envoyé. Nous vous répondrons dans les meilleurs délais!',
    sentMedia: 'Votre demande a bien été envoyé. Nous vous répondrons dans les meilleurs délais!',
    giants: 'Les géants',
    ticketPolicy: 'Consultez la politique d\'achat',
    privacy: {
        text: "© 2024 - Tous droits réservés",
        beet: 'Site conçu et développé par Beet',
        link: "Politique de confidentialité"
    }
}
