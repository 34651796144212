<template>
  <div class="navbar" :class="{'-sticky' : sticky}">
    <div class="navbar__section">
      <LangRouterLink
          v-for="link in links"
          :to="{name: link.name, params: link.params}"
          :key="link.label"
          class="navbar__link">
        {{ link.label }}
      </LangRouterLink>
    </div>

    <div class="navbar__center" @click="$router.push(getLocalRoute({name:'home'}))">
      <img :src="img" class="navbar__center__img" height="150" width="120"/>
    </div>

    <div class="navbar__section -end">
      <div class="navbar__socials" v-if="$beet.isReady && $beet.options.contact">
        <a :href="$beet.options.contact.facebook" target="_blank"><Icon id="facebook"/></a>
        <a :href="$beet.options.contact.instagram" target="_blank"><Icon id="instagram"/></a>
        <a :href="$beet.options.contact.linkedin" target="_blank"><Icon id="linkedin"/></a>
        <a :href="$beet.options.contact.twitter" target="_blank"><Icon id="twitter"/></a>
        <a href="https://www.youtube.com/c/fetesnouvellefrance" target="_blank"><Icon id="youtube"/></a>
      </div>

      <LangRouterLink :to="{name: 'tickets'}" class="button">
        {{$t('links.tickets')}}
      </LangRouterLink>
      <button class="navbar__lang" @click="$i18n.locale = otherLang.toLowerCase()"> {{ otherLang }}</button>
    </div>

    <div class="navbar__mobile">
      <LangRouterLink :to="{name: 'tickets'}" class="button -ticket"><Icon id="ticket"/></LangRouterLink>
      <icon :id="open ? 'close' : 'burger'" @click="toggle"/>
    </div>
    <div v-if="open" class="navbar__open">
      <div class="navbar__open-links">
        <LangRouterLink
          v-for="link in links"
          :to="{name: link.name, params: link.params}"
          :key="link.label"
          class="navbar__open-link">
        {{ link.label }}
      </LangRouterLink>
        <button class="navbar__lang -mobile" @click="$i18n.locale = otherLang.toLowerCase()"> {{ otherLang }}</button>
        <div class="row">
          <a :href="$beet.options.contact.facebook" target="_blank" class="navbar__open-social"><Icon id="facebook"/></a>
          <a :href="$beet.options.contact.instagram" target="_blank" class="navbar__open-social"><Icon id="instagram"/></a>
          <a :href="$beet.options.contact.linkedin" target="_blank" class="navbar__open-social"><Icon id="linkedin"/></a>
          <a :href="$beet.options.contact.twitter" target="_blank" class="navbar__open-social"><Icon id="twitter"/></a>
          <a href="https://www.youtube.com/c/fetesnouvellefrance" target="_blank" class="navbar__open-social"><Icon id="youtube"/></a>
        </div>
      </div>
      <div class="navbar__open-medal">
        <LangRouterLink :to="{name:'tickets'}" class="section-split__medaillon">
          <div class="section-split__medaillon-content">
            <p class="header-home__block-medaillon__title">{{ $t('medalTitle') }}</p>
            <p class="header-home__block-medaillon__link">{{ $t('medalText') }}
              <Icon id="link" class="header-home__block-medaillon__icon"/>
            </p>
          </div>
          <div class="section-split__medaillon-medal -right"/>
        </LangRouterLink>
      </div>
    </div>
  </div>

</template>

<script>
import LangRouterLink from "@/components/global/LangRouterLink";
export default {
  name: "SiteNav",
  components: {LangRouterLink},
  props: {
    sticky: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open : false,

    }
  },
  computed: {
    img() {
      return this.sticky ?
          this.$beet.options?.branding?.logoSquare?.original || '/img/logo_brun_small.svg' :
          this.$beet.options?.branding?.logoBanner?.original || '/img/logo_brun.svg'
    },
    otherLang() {
      return this.$i18n.locale === 'fr' ? 'EN' : 'FR'
    },
    links() {
      if(this.$beet.options.menu && this.$store.state.pages) {
        const links = []
        const routes = this.$router.getRoutes()
        let routeParams = {}

        this.$beet.options.menu.entries.forEach(entry => {
          let routeName = this.$store.state.pages[Number(entry.page)].key
          if(!routes.some(({name}) => name === routeName)) {
            routeName = 'page'
            routeParams = {slug: this.$store.state.pages[Number(entry.page)].slugs[this.$i18n.locale]}

          }

          links.push({
            name: routeName,
            label: entry.name,
            params: routeParams
          })
        })
        return links
      }
      return [
        {name: 'programmation', label: this.$t('links.program')},
        {name: 'fetes', label: this.$t('links.fete')},
        {name: 'info', label: this.$t('links.info')},
        {name: 'contact', label: this.$t('links.contact')}
      ]
    }
  },
  watch: {
    '$route'() {
      if(this.open) {
        this.toggle()
      }
    }
  },
  methods: {
    toggle() {
      const root = document.getElementsByTagName( 'html' )[0]
      this.open = !this.open
      if(this.open) {
        document.body.classList.add("no-scroll");
        root.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
        root.classList.remove("no-scroll");
      }
    }
  }
}
</script>
