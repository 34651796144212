<template>
  <section class="timeline section" v-if="items.length">
    <h2 class="section__title -big" v-scroll-reveal>{{ title }}</h2>

    <div class="timeline__row">
      <div class="timeline__item" v-for="(item, key) in currentItems" :key="key">
        <div class="timeline__line"/>
        <div class="timeline__content">
          <div class="timeline__date" v-scroll-reveal> {{item.years}} </div>
          <div class="timeline__title" v-scroll-reveal> {{item.name}} </div>
          <div class="timeline__text" v-scroll-reveal> {{item.description}} </div>
        </div>
      </div>
    </div>

    <div class="timeline__arrows">
      <button @click="prev" :class="{'-disabled': !canPrev}">
        <Icon id="arrow" style="transform: rotate(180deg); margin-right: 1rem"/>
      </button>
      <button @click="next" :class="{'-disabled': !canNext}">
        <Icon id="arrow"/>
      </button>
    </div>
    <div class="timeline__more" v-if="canNext" @click="next()">
      {{ $t('seeMore') }} {{ $t('histories') }} <Icon id="plus" style="font-size: 0.6rem; margin-left: 0.5rem"/>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionTimeline",
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      page: 0,
    }
  },
  computed: {
    currentItems() {
      return [this.items[this.page], this.items[this.page + 1], this.items[this.page + 2]]
    },
    items() {
      if(this.$beet.histories) {
        const items = Object.values(this.$beet.histories)
        if(items) {
          return items
        }
      }
      return []
    },
    canNext() {
      return this.page !== (this.items.length - 3)
    },
    canPrev() {
      return this.page !== 0
    }
  },
  methods: {
    next() {
      if(this.canNext) {
        this.page++
      }
    },
    prev() {
      if(this.canPrev) {
        this.page--
      }
    }
  }
}
</script>
