<template>
  <section class="section" v-if="pages.length">
    <div class="giant-title">
      <h2 class="section__title -big" v-scroll-reveal>{{ title }}</h2>
      <div v-scroll-reveal class="giant__arrows">
        <button  @click="prev" :class="{'-disabled': !canPrev}">
          <Icon id="arrow" style="transform: rotate(180deg); margin-right: 1rem"/>
        </button>
        <button @click="next" :class="{'-disabled': !canNext}">
          <Icon id="arrow" />
        </button>
      </div>
    </div>

    <div class="vetement">
      <transition name="fade" mode="out-in" appear>
        <div class="vetement__img" :style="{backgroundImage: `url(${current.items[selected].image.md || current.items[selected].image.lg || current.items[selected].image.original})`}" :key="current.items[selected].image"/>

      </transition>
      <div class="vetement__content" v-scroll-reveal="{delay: 150}">
        <div class="vetement__title">{{current.title}}</div>

        <div class="vetement__list">
          <button v-for="(item, key) in current.items"
                  :key="key"
                  @click="selected = key"
                  class="button -full vetement__list-item"
                  :class="{'-outline': selected !== key}">
            {{item.name}}
          </button>
        </div>

        <p class="vetement__text">
          {{current.items[selected].description}}
        </p>

      </div>
    </div>

    <div class="giant__seemore" v-if="canNext" @click="next()">
      {{ $t('seeMore') }} {{ $t('clothes') }} <Icon id="plus" style="font-size: 0.6rem; margin-left: 0.5rem"/>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionVetement",
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      page: 0,
      selected: 0
    }
  },
  computed: {
    ranks() {
      if(this.$beet.socialRanks) return Object.values(this.$beet.socialRanks)
      return []
    },
    clothes() {
      if(this.$beet.clothings) return Object.values(this.$beet.clothings)
      return []
    },
    pages() {
      const pages = []
      this.ranks.forEach(rank => {
        const clothes = this.clothes.filter(({socialRankId}) => socialRankId === rank.id)
        pages.push({
          title: rank.name,
          items: clothes
        })
      })
      return pages
    },
    current() {
      return this.pages[this.page]
    },
    canNext() {
      return this.page < 2
    },
    canPrev() {
      return this.page !== 0
    }
  },
  methods: {
    next() {
      if(this.page < 2) {
        this.selected = 0
        this.page++
      }
    },
    prev() {
      if(this.page !== 0) {
        this.selected = 0
        this.page--
      }
    }
  }
}
</script>
