<template>
  <template v-for="section in sections" :key="section.flexKey">
    <component :is="getComponent(section)" v-bind="getProps(section)"></component>
  </template>
</template>

<script>
import SectionCta from "@/components/SectionCta.vue";
import SectionSplit from "@/components/SectionSplit.vue";
import SectionVideo from "@/components/SectionVideo.vue";
import SectionTimeline from "@/components/SectionTimeline.vue";
import SectionGiants from "@/components/SectionGiants.vue";
import SectionVetement from "@/components/SectionVetement.vue";
import SectionEvents from "@/components/SectionEvents.vue";
import SectionPartners from "@/components/SectionPartners.vue";
import SectionMedaillon from "@/components/SectionMedaillon.vue";
import SectionFaq from "@/components/SectionFaq.vue";
import SectionLinks from "@/components/SectionLinks.vue";


export default {
  name: "PageBuilder",
  components: {
    SectionLinks,
    SectionFaq,
    SectionMedaillon,
    SectionPartners,
    SectionEvents, SectionVetement, SectionGiants, SectionTimeline, SectionVideo, SectionSplit, SectionCta},
  props: {
    sections: {
      type: Array,
      required: true
    }
  },
  methods: {
    getComponent(section) {
      if(section.flexLayout === 'text_section') {
        const withImage = Boolean(Number(section.withImg))
        if(withImage) return 'SectionSplit'
        return 'SectionCta'
      }
      if(section.flexLayout === 'large_video_section') return 'sectionVideo'
      if(section.flexLayout === 'history_section') return 'SectionTimeline'
      if(section.flexLayout === 'giants_section') return 'SectionGiants'
      if(section.flexLayout === 'clothing_section') return 'SectionVetement'
      if(section.flexLayout === 'events_section') return 'SectionEvents'
      if(section.flexLayout === 'partners_section') return 'SectionPartners'
      if(section.flexLayout === 'medallion_section') return 'SectionMedaillon'
      if(section.flexLayout === 'faq_section') return 'SectionFaq'
      if(section.flexLayout === 'todo_section') return 'SectionLinks'
    },
    getProps(section) {
      let data = {...section }

      if(section.flexLayout === 'text_section') {
        if(section.withBtn) {
          data.buttonLabel = section.btnText
          if(section.btnLink.includes('http')) {
            data.buttonAction = () => window.open(section.btnLink, '_blank')
          } else {
            data.buttonAction = () => this.$router.push(section.btnLink)
          }
        }
        if(section.withImg) {
          data.reverse = section.imageSide !== 'right'
          data.image = (section.image?.md || section.image?.original)
        }
      }
      return data
    }
  }
}
</script>

