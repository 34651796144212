import { createStore } from 'vuex'
import http from '@/extensions/http'

export default createStore({
  state: {
    pages: null
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    pages({state}) {
      return http.get('/pages').then(response => {
        state.pages = response.data
      })
    },
    newsletter({}, {lang, firstName, lastName, email}) {
      return http.post('/newsletter', {
        lang, firstName, lastName, email
      })
    },
    contact({}, {firstName, lastName, email, subject, message}) {
      return http.post('/contact', {
        firstName, lastName, email, subject, message
      })
    },
    media({}, {firstName, lastName, media, phone, email, goal}) {
      return http.post('/media-accreditation', {
        firstName, lastName, media, phone, email, goal
      })
    }
  },
  modules: {
  }
})
