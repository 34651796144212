<template>
  <a class="card-link" :href="linkUrl" target="_blank">
    <div class="card-link__img" :style="{backgroundImage: `url(${img})`}"/>
    <div class="button -transparent">
      {{linkLabel}}
      <Icon id="link"/>
    </div>
  </a>
</template>

<script>
export default {
name: "CardLink",
  props: {
    img: {
      type: String,
    },
    linkLabel: {
      type: String,
      default: 'Réservez',
    },
    linkUrl: {
      type: String,
      default: '#'
    }
  }
}
</script>
