<template>
  <template v-if="$page">
    <div class="new-header">
      <div class="new-header__image" :style="{backgroundImage : `url(${$page.header.image.lg || $page.header.image.original})`}"/>

      <div class="new-header__content">
        <h1 class="header__title" v-scroll-reveal>{{ $page.header.title }}</h1>
        <p class="header__text" v-scroll-reveal>{{ $page.header.subtitle }}</p>
      </div>
    </div>

    <PageBuilder :sections="$page.sections"/>

    <!--

      <SectionSplit
          reverse
          :vente="$page.sale.text"
          :vente-title="$page.sale.title"
          :title="$page.medallion.title"
          :text="$page.medallion.text"
          :image="$page.medallion.image"
          :button-label="$page.medallion.buttonLabel"
          pdv="fnfmed2023"
          :button-action="() => {}"
          :medaillon="{title: $t('medalTitle'), text: $t('participateText'), link: () => $router.push(getLocalRoute({name: 'event', params: {slug: chasseSlug}}))}"
          :saleSpot="$page.sale.spots"
      />

      <SectionSplit
          :title="$page.partners.title"
          :text="$page.partners.text"
          :image="$page.partners.image"
          icon="birds"
      />

      <SectionCta
          :title="$page.history.title"
          :text="$page.history.text"
          icon="birds"
      />

      <SectionSplit
          :vente-title="$page.sale.title"
          :title="$page.royal.title"
          :text="$page.royal.text"
          :button-label="$t('buyRoyal')"
          pdv="forfait2023"
          :image="$page.royal.image"
      />

      <SectionSplit
          v-for="(plan,key) in $page.plans"
          :key="key"
          :reverse=" key % 2 === 0"
          :title="plan.title"
          :text="plan.text"
          :button-label="plan.buttonLabel"
          :pdv="plan.lpvEventId"
          :image="plan.image"
      />

      <SectionCta
          light
          :title="$page.activities.title"
          :text="$page.activities.text"
          icon="birds"
          :button-label="$page.activities.buttonLabel"
          :button-action="() => {$router.push(getLocalRoute({name:'program', hash: '#events' }))}"
      />

      <SectionSplit
          reverse
          :title="$page.offers.title"
          :text="$page.offers.text"
          :image="$page.offers.image"
          icon="birds"
      />
      -->

  </template>
  <template v-else>
    <div style="height: 100vh"/>
  </template>

</template>

<script>
import SectionSplit from "@/components/SectionSplit";
import SectionCta from "@/components/SectionCta";
import {setMeta} from "@/helpers/metaHelpers";
import PageBuilder from "@/components/PageBuilder.vue";
export default {
  name: "Tickets",
  beetPage: 'Billetterie',
  components: {PageBuilder, SectionCta, SectionSplit},
  beforeMount() {
    if(this.$page) setMeta(this.$page.seo)
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    }
  },
  computed: {
    siteHidden(){
      if(this.$route.query.hidden === 'false') return false
      return process.env.VUE_APP_SITE_HIDDEN === '1'
    },
    chasseSlug() {
      return this.$i18n.locale === 'fr' ? 'la-chasse-au-tresor-td' : 'td-treasure-hunt'
    }
  }
}
</script>
