export default {
    hello: 'Hello',
    partners: 'Partners',
    contact: 'Contact us',
    subscribe: 'Sign up',
    visit: 'Visit website',
    reserve: 'Book your seat',
    thanks: 'Thank you',
    sanitary: 'Consult sanitary rules',
    medalTitle: 'Participate to Les Fêtes de la Nouvelle-France TD',
    medalText: 'Get the Decoder-Medallion',
    participateTitle: 'Join the TD Treasure Hunt',
    participateText: 'Get more details about the Hunt',
    allEvent: 'Throughout the festival',
    backToProg: 'Back to program',
    buyRoyal: 'Buy the Royal Package',
    knowMore: 'Know more',
    seeMore: 'See more',
    histories: 'histories',
    giant: 'giants',
    clothes: 'clothings',
    mediaBack: 'Go back to Media',
    classicbox: 'The Classic',
    vegebox: 'The Vege',
    carnaval: 'Note that the event is managed by',
    personalInfo: "Protection of Personal Information is under Marie-Ève Jacob",
    concour: {
        title: 'For the 25th anniversary of Les Fêtes de la Nouvelle-France TD, win a VIP evening at Chez Justine',
        text: 'As part of the 25th anniversary of the TD New France Festival, win a gift certificate for a VIP evening for you and 4 of your friends at the speakeasy bar and restaurant Chez Justine located in the heart of Old Quebec.',
        button: 'Participate'
    },
    links: {
        home: 'Home',
        program: 'Program',
        fete: 'About Les Fêtes',
        info: 'Useful informations',
        contact: 'Contact',
        team: 'Team and partners',
        media: 'Medias',
        words: 'Words  from dignitaries',
        faq: 'Frequently asked questions',
        tickets: 'Tickets'
    },
    firstName: 'First name',
    lastName: 'Name',
    email: 'Email',
    phone: 'Phone',
    subject: 'Subject',
    goal: 'Purpose of the accreditation request',
    send: 'Send a message',
    submit: 'Submit request',
    sent: 'Your message has been sent. We will respond as soon as possible!',
    giants: 'The giants',
    ticketPolicy: 'Consult the purchasing policy',
    privacy: {
        text: "© 2024 - All rights reserved",
        beet: 'Site conçu et développé par Beet',
        link: "Privacy policy"
    }
}
