<template>
  <div>
    404
  </div>
</template>

<script>

export default {
  name: 'NotFound',
  beforeMount() {
    this.$router.replace({name: 'home'})
  }
}
</script>
