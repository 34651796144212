<template>
  <section class="section-split -margin" :class="{'-reverse' : coverSide === 'left'}">
    <div class="section-split__content">
      <div class="section-split__title" v-scroll-reveal> {{ title }}</div>

      <div class="section-split__separator" v-scroll-reveal>
        <div class="section-split__line"/>
        <Icon id="flags" class="section-split__icon"/>
        <div class="section-split__line"/>
      </div>

      <div class="raw-html" style="margin: 0;" v-scroll-reveal>
        {{text || description}}
      </div>
      <div class="row -mobilecol">


        <button v-if="lpdvId" class="button cta__button tpos-add-to-cart" type="button" :data-tpos-event="lpdvId">
          {{ btnBuyText }}
        </button>

        <button class="button cta__button" v-else @click="buttonAction" v-scroll-reveal>
          {{ btnBuyText }}
        </button>

        <button  class="button cta__button -transparent" @click="downloadPolicy()">
          {{ btnPolicyText }}
          <Icon id="link" class="header-home__block-medaillon__icon"/>
        </button>
      </div>

      <div class="section-split__vente" v-scroll-reveal>
        <div class="section-split__vente-title">{{ saleSpotsTitle }}</div>
        <p>{{ saleSpotsDescription }}</p>
        <div class="section-split__vente-geo" v-if="saleSpotsItems">
          <template v-for="(spot, key) in saleSpotsItems" :key="key">
            <div class="section-split__vente-geo-item" v-if="spot.lat && spot.long" @click="goToSpot(spot)">
              <Icon id="geo"/>
              {{ spot.name }}
            </div>
          </template>

        </div>
      </div>
    </div>

    <div class="section-split__media">
      <div class="section-split__media--img"
           :style="{backgroundImage: `url(${coverImage?.lg || coverImage?.original})`}" v-scroll-reveal/>
      <div class="section-split__medaillon" v-scroll-reveal>
        <div class="section-split__medaillon-medal"
             :style="{backgroundImage: `url(${medallionImage.xs || medallionImage.original})`}"/>

        <div class="section-split__medaillon-content" v-if="huntDetailBtn">
          <p class="header-home__block-medaillon__title">{{ huntDetailTitle }}</p>
          <p class="header-home__block-medaillon__link" @click="openLink(huntDetailBtnLink)">{{ huntDetailBtn }}
            <Icon id="link" class="header-home__block-medaillon__icon"/>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionMedaillon",
  props: {
    lpdvId: {
      type: String
    },
    coverSide: {
      type: String
    },
    reverse: {
      type: Boolean,
      default: false
    },
    pdv: {
      type: Number,
      default: null
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    description: {
      type: String
    },
    coverImage: {
      type: Object
    },
    medallionImage: {
      type: Object
    },
    btnBuyText: {
      type: String
    },
    buttonAction: {
      type: Function
    },
    saleSpotsTitle: {
      type: String,
      default: null
    },
    saleSpotsDescription: {
      type: String
    },
    huntDetailBtn: {Type: String},
    huntDetailBtnLink: {Type: String},
    huntDetailTitle: {Type: String},
    saleSpotsItems: {
      type: Array,
      default: null
    },
    btnPolicyText: {
      type: String
    }
  },
  methods: {
    openLink(link) {
      link.includes('http')
          ? window.open(link, '_blank')
          : this.$router.push(link)
    },
    goToSpot(spot) {
      return window.open(`https://www.google.com/maps/dir/?api=1&destination=${spot.lat}, ${spot.long}`, '_blank')
    },
    downloadPolicy() {
      console.log(this.$beet.options)
      const url = this.$beet.options.files.buyerPolicy.original
      if(url) return window.open(url, '_blank', 'download')
    }
  }
}
</script>
