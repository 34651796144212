<template>
  <template v-if="$page">
    <div class="new-header">
      <div class="new-header__image" :style="{backgroundImage : `url(${$page.header.image?.lg || $page.header.image?.original})`}"/>

      <div class="new-header__content">
        <h1 class="header__title">{{ $page.header.title }}</h1>
        <p class="header__text">{{ $page.header.subtitle }}</p>
      </div>
    </div>

    <PageBuilder :sections="$page.sections"/>
  </template>

  <template v-else>
    <div style="min-height: 100vh"/>
  </template>

</template>

<script>
import PageBuilder from "@/components/PageBuilder.vue";
import {setMeta} from "@/helpers/metaHelpers";

export default {
  name: 'page',
  components: {PageBuilder},
  computed: {
    currentPage() {
      if(this.$store.state.pages) {
        return Object.values(this.$store.state.pages).find(({slugs}) => Object.values(slugs).includes(this.$route.params.slug))
      }
      return null
    },
    $page() {
      const beetPage = this.currentPage?.key
      return this.$store.state.beet.pages[beetPage] || null
    }
  },
  watch: {
    currentPage: {
      immediate: true,
      handler: function(to){
        if(to) this.getPage()
      }
    },
    watch: {
      '$page'(to) {
        setMeta(to.seo)
      }
    }
  },
  methods: {
    getPage() {
      const beetPage = this.currentPage.key
      this.$store.dispatch('fetchPage', [beetPage, this.$i18n.locale])
    }
  }
}
</script>