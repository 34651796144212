<template>
  <template v-if="$page?.header">
    <div class="new-header">
      <div class="new-header__image"
           :style="{backgroundImage : `url(${$page.header.image.lg || $page.header.image.original})`}"/>

      <div class="new-header__content">
        <h1 class="header__title" v-scroll-reveal>{{ $page.header.title }}</h1>
        <p class="header__text" v-scroll-reveal="{delay: 225}">{{ $page.header.subtitle }}</p>
      </div>
    </div>

    <template v-if="siteHidden">
      <section class="program-intro">
        <h2 class="program-intro__title">À venir!</h2>
      </section>
    </template>

    <template v-else>
      <section class="program-intro">
        <h2 class="program-intro__title" v-scroll-reveal>{{ $page.cta.title }}</h2>

        <p class="program-intro__text" v-scroll-reveal>{{ $page.cta.subtitle }}</p>

        <div class="row program-intro__buttons" v-scroll-reveal>
          <a :href="programPdf" target="_blank" class="button">{{ $page.cta.btnProg }}</a>

          <a :href="mapPdf" target="_blank" class="button -transparent">
            {{ $page.cta.btnMap }}
            <Icon id="link" style="margin-left: 0.5rem; font-size: 0.75rem"/>
          </a>
        </div>
      </section>

      <section class="program-places" v-scroll-reveal>
        <LangRouterLink :to="{name: 'site', params: {slug: card.slugs[$i18n.locale]}}"
                        :href="card.link" class="program-places__card" v-for="(card, key) in sites" :key="key">
          <div class="program-places__img"
               :style="{backgroundImage:  `url('${card.image?.md || card.image?.original}')`}"/>

          <div class="program-places__title">{{ card.name }}</div>
          <div class="program-places__content">
            <div class="program-places__geo row">
              <Icon id="geo"/>
              {{ card.locationName }}
            </div>

            <div class="program-places__link">
              <Icon id="arrow"/>
            </div>
          </div>

        </LangRouterLink>
      </section>
    </template>

    <PageBuilder :sections="$page.sections"/>

    <!--
    <template v-else>
      <section class="program-intro">
        <h2 class="program-intro__title" v-scroll-reveal>{{ $page.sites.title }}</h2>

        <p class="program-intro__text" v-scroll-reveal>{{ $page.sites.text }}</p>

        <div class="row program-intro__buttons" v-scroll-reveal>
          <a :href="programPdf" target="_blank" class="button" >{{ $page.sites.medallionButton }}</a>

          <a :href="mapPdf" target="_blank" class="button -transparent" >
            {{ $page.sites.mapButton }}
            <Icon id="link" style="margin-left: 0.5rem; font-size: 0.75rem"/>
          </a>
        </div>
      </section>

      <section class="program-places" v-scroll-reveal>
        <LangRouterLink :to="{name: 'site', params: {slug: card.slugs[$i18n.locale]}}"
                        :href="card.link" class="program-places__card" v-for="(card, key) in sites" :key="key">
          <div class="program-places__img" :style="{backgroundImage: `url(${card.img})`}" />

          <div class="program-places__title" >{{card.name}}</div>
          <div class="program-places__content">
            <div class="program-places__geo row" >
              <Icon id="geo"/>
              {{card.locationName}}
            </div>

            <div class="program-places__link" >
              <Icon id="arrow"/>
            </div>
          </div>

        </LangRouterLink>
      </section>

      <section class="program-quests">
        <h2 class="program-quests__title" v-scroll-reveal>{{ $page.hunt.title }}</h2>

        <p class="program-quests__text" v-scroll-reveal>{{$page.hunt.text}}</p>

        <div class="program-quests__cards" v-scroll-reveal>
          <LangRouterLink :to="{name:'event', params: {slug: card.slugs[$i18n.locale]}}" class="program-quests__card" v-for="(card, key) in hunt" :key="key">
            <div class="program-quests__card-img" :style="{backgroundImage: `url(${card.img})`}"/>

            <div class="program-quests__card-content">
              <div>
                <div class="program-quests__card-title">
                  {{card.name}}
                </div>

                <div class="program-quests__card-row">
                  <Icon id="geo"/> {{card.locationName}}
                </div>

                <div class="program-quests__card-row">
                  <Icon id="date"/> {{$t('allEvent')}}
                </div>
              </div>

              <div>
                <a href="#" class="program-quests__card-link">
                  <Icon id="arrow"/>
                </a>
              </div>
            </div>
          </LangRouterLink>
        </div>
      </section>-->
  </template>
  <template v-else>
    <div style="min-height: 100vh"/>
  </template>

</template>

<script>

import {setMeta} from '@/helpers/metaHelpers'
import PageBuilder from '@/components/PageBuilder.vue'

export default {
  name: 'Program',
  components: {PageBuilder},
  beetPage: 'programmation',
  beforeMount() {
    if (this.$page) setMeta(this.$page.seo)
  },
  methods: {
    getDate(date) {
      const day = new Date(date).toLocaleDateString(`${this.$i18n.locale}-CA`, {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      })
      return day
    },
    getHour(date) {
      const time = new Date(date).toLocaleTimeString(`${this.$i18n.locale}-CA`, {
        hour: '2-digit',
        minute: '2-digit',
      })
      return time
    }
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    }
  },
  computed: {
    mapPdf() {
      if (this.$beet.options.files?.map) {
        return this.$beet.options.files?.map?.original || ''
      }
      return ''
    },
    programPdf() {
      if (this.$beet.options.files?.programmation) {
        return this.$beet.options.files?.programmation?.original || ''
      }
      return ''
    },
    siteHidden() {
      if (this.$route.query.hidden === 'false') return false
      return process.env.VUE_APP_SITE_HIDDEN === '1'
    },
    hunt() {
      if (this.$beet.isReady) {
        return this.$beet.events.filter(({isTreasureHunt}) => isTreasureHunt)
      }
      return []
    },
    punctual() {
      if (this.$beet.isReady) {
        return Object.values(this.$beet.events).filter(({time, times}) => time || times).sort((a, b) => {
          if (a.order < b.order) return -1
          if (a.order > b.order) return 1
          return 0
        })
      }
      return []
    },

    sites() {
      if (this.$beet.isReady) {
        const sites = Object.values(this.$beet.sites)
        return sites.sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          }
          if (a.order > b.order) {
            return 1;
          }
          return 0;
        });
      }
      return []
    }
  }
}
</script>
