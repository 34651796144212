<template>
  <section class="section">
    <h3 class="section__title">{{ title }}</h3>
    <div class="info-cards">
      <div class="card-link info-cards__card" v-for="(card, key) in partner" :key="key"  @click="openLink(card.url)">
        <div class="card-link__img" :style="{backgroundImage: `url(${card.logo?.xs || card.logo?.original})`}"/>
        <a :href="card.url" target="_blank" class="button -transparent" style="padding: 0.75rem 0.25rem;">
          {{ card.name }}
          <Icon id="link"/>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionPartners",
  props: {
    backgroundColor: {type: String},
    description: {type: String},
    title: {type: String},
    partnerType: {type: String},
  },
  computed: {
    partner() {
      if (!this.$beet.isReady) return []
      const arr = Object.values(this.$beet.partners)
      const foo = arr.filter(({partnerTypeId}) => partnerTypeId == Number(this.partnerType))
      console.log(arr, this.partnerType)
      return foo
    }
  },
  methods: {
    openLink(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
