<template>
  <template v-if="$page">
    <div class="new-header" >
      <div class="new-header__image" :style="{backgroundImage : `url(${$page.header.image?.lg || page.header.image?.original})`}"/>

      <div class="new-header__content">
        <h1 class="header__title">{{ $page.header.title }}</h1>
        <p class="header__text">{{ $page.header.subtitle }}</p>
      </div>
    </div>

    <section class="section media">
      <div class="media__form">
        <h2 class="section__title">{{ $page.form.title }}</h2>

        <p class="section__text" v-html="$page.form.subtitle"></p>

        <br/>
        <transition name="fade" appear mode="out-in">
          <div v-if="sent" class="contact-form__confirm -media" >
            <div>
              <Icon id="confirm"/>
              <div>{{$t('sentMedia')}}</div>
            </div>

          </div>

          <form @submit.prevent="post" v-else class="media__form-form">
            <div class="row">
              <label class="input">
                <span>{{ $t('firstName') }}</span>
                <input type="text" class="input__text" required v-model="form.firstName"/>
              </label>

              <label class="input">
                <span>{{ $t('lastName') }}</span>
                <input type="text" class="input__text" required v-model="form.lastName"/>
              </label>
            </div>

            <div class="row">
              <label class="input">
                <span>Média</span>
                <input type="text" class="input__text" required v-model="form.media"/>
              </label>

              <label class="input">
                <span>{{ $t('phone') }}</span>
                <input type="text" class="input__text" required v-model="form.phone"/>
              </label>
            </div>

            <div class="row">
              <label class="input">
                <span>{{ $t('email') }}</span>
                <input type="text" class="input__text" required v-model="form.email"/>
              </label>
            </div>

            <div class="row">
              <label class="input">
                <span>{{ $t('goal') }}</span>
                <textarea class="input__text" rows="4" required v-model="form.goal"/>
              </label>
            </div>
            <br/>
            <div class="row -end">
              <button class="button">{{$t('submit')}}</button>
            </div>
          </form>
        </transition>
      </div>


      <div class="media__aside">
        <h3 class="media__aside-title">{{ $page.manager.title }}</h3>
        <p>{{ $page.manager.name }}</p>
        <div class="media__aside-row"><Icon id="phone"/> {{ $page.manager.phone }} </div>
        <a class="media__aside-row" :href="`mailto:${$page.manager.email}`"><Icon id="mail"/> {{ $page.manager.email }} </a>
        <br/>
        <h3 class="media__aside-title">{{ $page.kit.title }}</h3>
        <a :href="item.link" download target="_blank" class="media__aside-row -download" v-for="(item, key) in $page.medias" :key="key">
          <Icon id="download"/> {{ item.name }}
        </a>
        <br/>
        <p>{{ $page.kit.contactMsg }}</p>
      </div>
    </section>

    <section class="section -left">
      <h2 class="section__title">{{ $page.press.title }}</h2>

      <div>
        <div v-for="(item, key) in press" :key="key" class="media-item">
          <div class="media-item__content">
            <h4 class="media-item__title"> {{item.name}} </h4>
            <div class="media-item__date">
              <Icon id="date"/> {{getDate(item.publishedAt)}}
            </div>
            <p v-html="`${item.content.substr(0,460)}...`">
            </p>
          </div>

          <div class="media-item__link">
            <LangRouterLink :to="{name:'mediaPress', params: {slug: item.slugs[$i18n.locale]}}" class="program-events__card-link">
              <Icon id="arrow"/>
            </LangRouterLink>
          </div>
        </div>
      </div>
    </section>

    <PageBuilder :sections="$page.sections"/>
  </template>
  <template v-else>
    <div style="min-height: 100vh"/>
  </template>

</template>

<script>

import {setMeta} from "@/helpers/metaHelpers";
import PageBuilder from "@/components/PageBuilder.vue";

export default {
  name: 'Media',
  components: {PageBuilder},
  beetPage: 'Médias',
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        media: '',
        phone: '',
        email: '',
        goal: ''
      },
      sent: false
    }
  },
  computed: {
    press() {
      if(this.$beet.pressReleases) {
        const releases = [...Object.values(this.$beet.pressReleases)]
        return releases.sort((a,b) => {
          const aDate = new Date(a.publishedAt).getTime()
          const bDate = new Date(b.publishedAt).getTime()
          if(aDate < bDate) return 1
          if(aDate > bDate) return -1
          return 0
        })
      }
      return []
    }
  },
  methods: {
    showRecaptcha() {
      document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'visible'
    },
    getDate(date) {
      const day = new Date(date).toLocaleDateString(`${this.$i18n.locale}-CA`, {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      })
      return day
    },
    post() {
      this.$recaptcha('contact').then(() => {
        if(this.form.email.includes('@')) {
          this.$store.dispatch('media', this.form).then(() => {
            this.sent = true
            document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'hidden'
          })
        }
      })
    }
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    },
    form: {
      deep: true,
      handler() {
        this.showRecaptcha()
      }
    }
  }
}
</script>
