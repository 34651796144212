<template>
  <SiteNav :sticky="stickyNav"/>
  <router-view/>
  <SiteFooter/>
  <Gdpr :options="{backgroundColor: '#EDE5E1', color: '#875434'}"/>
</template>

<script>
import SiteNav from "@/components/SiteNav";
import SiteFooter from "@/components/SiteFooter";
import Gdpr from "@/components/gdpr/Gdpr.vue";

export default {
  name: 'App',
  components: {Gdpr, SiteFooter, SiteNav },
  data() {
    return {
      stickyNav: false,
      windowHeight: window.innerHeight,
      pdv: null
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
    this.$store.dispatch('pages')
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    '$i18n.locale': {
      handler: function(to) {
        if(this.pdv) {
          const el = document.getElementById('pdv')
          document.head.removeChild(el)
        }
        this.initPdv()

        this.$beet.fetchCoreData(to)
        if (this.$route.name && !this.$route.name.includes(`:${to}`)) {
          let routeName = this.$route.name
          if (routeName.indexOf(':') !== -1) {
            routeName = routeName.substr(0, routeName.indexOf(':'))
          }
          if (to === this.$defaultLang) {
            this.$router.replace({name: routeName})
          } else {
            routeName = `${routeName}:${to}`
            this.$router.replace({name: routeName})
          }
        }
      },
      immediate: true
    },
    '$route.name'(to, from) {
      if(to !== from && !this.$route.hash) {
        window.scrollTo(0,0);
      }
    },
    '$route': {
      handler: function (to) {
        if (to && to.name) {
          const routeName = to.name
          const routeLangIsDefault = routeName.indexOf(':') === -1
          if (routeLangIsDefault && this.$i18n.locale === this.$defaultLang) return;
          if (routeLangIsDefault && this.$i18n.locale !== this.$defaultLang) {
            this.$i18n.locale = this.$defaultLang
            return;
          }
          const routeLang = routeName.substr(routeName.indexOf(':'), routeName.length)
          if (routeLang === this.$i18n.locale) return;
          else this.$i18n.locale = routeLang.replace(':', '')
        }
      },
      immediate: true
    },
    '$beet.options'(to) {
      if(to.branding) {
        const root = document.querySelector(":root")
        root.style.setProperty('--color-primary', to.branding.primary);
        root.style.setProperty('--color-accent', to.branding.secondary);
      }
    }
  },
  methods: {
    initPdv() {
      this.pdv = document.createElement('script');
      this.pdv.src = `https://lepointdevente.com/plugins/embed.js?lang=${this.$i18n.locale}`
      this.pdv.id = 'pdv'
      document.head.appendChild(this.pdv)
    },
    handleScroll(e) {
      const pos = e.target.scrollingElement.scrollTop
      if(pos > this.windowHeight) {
        this.stickyNav = true
      } else if (this.stickyNav) {
        this.stickyNav = false
      }
    }
  }
}
</script>
