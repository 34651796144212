<template>
  <section class="section-split" :class="{'-reverse' : reverse, '-pad': backgroundImage}" :style="bgImage">
    <div class="section-split__content">
      <div class="section-split__title" v-scroll-reveal> {{title}} </div>

      <div class="section-split__separator" v-scroll-reveal>
        <div class="section-split__line"/>
        <Icon id="flags" class="section-split__icon"/>
        <div class="section-split__line"/>
      </div>

      <div  class="raw-html" style="margin: 0; white-space: pre-wrap" v-scroll-reveal>
        {{text || description}}
      </div>
      <div class="row -mobilecol">
        <button class="button cta__button" v-if="buttonLabel && !pdv" @click="buttonAction" v-scroll-reveal>
          {{buttonLabel}}
        </button>

        <button v-if="pdv" class="button cta__button tpos-add-to-cart" type="button" :data-tpos-event="pdv">
          {{buttonLabel}}
        </button>

        <button v-if="saleSpot" class="button cta__button -transparent" @click="downloadPolicy()">
          {{$t('ticketPolicy')}}
          <Icon id="link" class="header-home__block-medaillon__icon"/>
        </button>
      </div>

      <div class="section-split__vente" v-if="vente" v-scroll-reveal>
        <div class="section-split__vente-title">{{ venteTitle }}</div>
        <p>{{vente}}</p>
        <div class="section-split__vente-geo" v-if="saleSpot">
          <template v-for="(spot, key) in saleSpot" :key="key">
            <div class="section-split__vente-geo-item" v-if="spot.lat && spot.long" @click="goToSpot(spot)">
              <Icon id="geo"/>
              {{ spot.name }}
            </div>
          </template>

        </div>
      </div>
    </div>

    <div class="section-split__media" >
      <div class="section-split__media--img" :style="{backgroundImage: `url(${image})`}" v-scroll-reveal/>
      <div v-if="medaillon" class="section-split__medaillon" v-scroll-reveal>
        <div class="section-split__medaillon-medal" :style="{backgroundImage: `url(${$parent.$page.royal.smallImage})`}"/>

        <div class="section-split__medaillon-content">
          <p class="header-home__block-medaillon__title">{{ medaillon.title }}</p>
          <p class="header-home__block-medaillon__link" @click="medaillon.link">{{ medaillon.text }}
            <Icon id="link" class="header-home__block-medaillon__icon"/>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "SectionSplit",
  props: {
    backgroundColor: {
      type: String,
      default: 'primary'
    },
    reverse: {
      type: Boolean,
      default: false
    },
    pdv: {
      type: Number,
      default: null
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    description: {
      type: String
    },
    image: {
      type: String
    },
    buttonLabel: {
      type: String
    },
    buttonAction: {
      type: Function
    },
    backgroundImage: {

    },
  },
  computed: {
    bgImage() {
      if(this.backgroundColor === 'image') return {backgroundImage: `url(${this.backgroundImage?.lg ? this.backgroundImage.lg || this.backgroundImage.original : ''})`}
      return {}
    }
  }
}
</script>
