export const setMeta = seo => {
  document.title = seo.title || 'Fêtes de la Nouvelle-France TD';
  const metas = document.getElementsByTagName('meta')
  metas["og:title"].content = seo.title || 'Fêtes de la Nouvelle-France TD'
  metas["description"].content = seo.description || ''
  metas["og:description"].content = seo.description || ''
  metas["og:image"].content = seo.image || '/img/logo.svg'
  metas["og:type"].content = seo.type || 'website'
  metas["twitter:card"].content = seo.twitter || 'summary'
}
