<template>
  <section class="section" id="faq">
    <h2 class="section__title" v-scroll-reveal>{{title}}</h2>
    <p class="section__text -small" v-scroll-reveal>{{description}}</p>
    <div class="section__buttons" v-scroll-reveal v-if="btnText">
      <button class="button" @click="$router.push(btnLink)">{{btnText}}</button>
    </div>

    <div class="faq" >
      <div class="faq__item" v-for="(item, key) in faq" :key="key" @click="toggleFaq(key)" v-scroll-reveal>
        <div class="faq__row">
          <div>{{item.question}}</div>
          <Icon :id="faqOpen === key ? 'minus' : 'plus'"/>
        </div>

        <DropDown :is-open="key === faqOpen">
          <div class="faq__content" v-html="item.answer" />
        </DropDown>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "SectionFaq",
  data() {
    return {
      faqOpen: null
    }
  },
  props: {
    title: { type: String },
    description: { type: String },
    btnLink: { type: String },
    btnText: { type: String }
  },
  computed: {
    faq() {
      return this.$beet.questions || []
    }
  },
  methods: {
    toggleFaq(key) {
      this.faqOpen === key ? this.faqOpen = null : this.faqOpen = key
    },
  }
}
</script>
