<template>
  <template v-if="$page">
    <div class="new-header">
      <div class="new-header__image" :style="{backgroundImage : `url(${$page.header.image.lg || $page.header.image.original})`}"/>

      <div class="new-header__content">
        <h1 class="header__title" v-scroll-reveal>{{ $page.header.title }}</h1>
        <p class="header__text" v-scroll-reveal>{{ $page.header.subtitle }}</p>
      </div>
    </div>

    <section class="section">
      <h2 class="section__title" v-scroll-reveal>{{$page.form.title}}</h2>
      <p class="section__text" v-scroll-reveal v-html="$page.form.subtitle"></p>
      <br/>

      <transition name="fade" mode="out-in" appear>
        <div v-if="sent" class="contact-form__confirm" >
          <Icon id="confirm"/>
          <div>{{$t('sent')}}</div>
        </div>
        <form @submit.prevent="post" class="contact-form" v-else>
          <div class="row">
            <label class="input" v-scroll-reveal>
              <span>{{ $t('firstName') }}</span>
              <input type="text" class="input__text" required v-model="form.firstName"/>
            </label>

            <label class="input" v-scroll-reveal>
              <span>{{ $t('lastName') }}</span>
              <input type="text" class="input__text" required v-model="form.lastName"/>
            </label>
          </div>

          <div class="row">
            <label class="input" v-scroll-reveal>
              <span>{{ $t('email') }}</span>
              <input type="text" class="input__text" required v-model="form.email"/>
            </label>

            <label class="input" v-scroll-reveal>
              <span>{{ $t('subject') }}</span>
              <input type="text" class="input__text" required v-model="form.subject"/>
            </label>
          </div>

          <div class="row">
            <label class="input" v-scroll-reveal>
              <span>Message</span>
              <textarea class="input__text" rows="4" required v-model="form.message"/>
            </label>
          </div>
          <br/>
          <div class="row -end" v-scroll-reveal>
            <button class="button">{{ $t('send') }}</button>
          </div>
        </form>
      </transition>
    </section>

    <PageBuilder :sections="$page.sections" />

  </template>

  <template v-else>
    <div style="min-height: 100vh"/>
  </template>

</template>

<script>

import {setMeta} from "@/helpers/metaHelpers";
import PageBuilder from "@/components/PageBuilder.vue";

export default {
  name: 'Contact',
  components: {PageBuilder},
  beetPage: 'contact',
  data() {
    return {
      page: {
        headerImage: '/img/placeholder.jpg',
        title: 'Contactez-nous',
        subtitle: 'Nous sommes toujours à votre écoute'
      },
      form: {
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: ''
      },
      sent: false,
    }
  },
  beforeMount() {
    if(this.$page) setMeta(this.$page.seo)
  },
  methods: {
    showRecaptcha() {
      document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'visible'
    },
    post() {
      this.$recaptcha('contact').then(() => {
        if(this.form.email.includes('@')) {
          this.$store.dispatch('contact', this.form).then(() => {
            this.sent = true
            document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'hidden'
          })
        }
      })
    }
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    },
    form: {
      deep: true,
      handler() {
        this.showRecaptcha()
      }
    }
  }
}
</script>
