<template>
  <template v-if="$page">
    <div class="new-header">
      <div class="new-header__image" :style="{backgroundImage : `url(${$page.header.image?.lg || $page.header.image?.original})`}"/>

      <div class="new-header__content">
        <h1 class="header__title">{{ $page.header.title }}</h1>
        <p class="header__text">{{ $page.header.subtitle }}</p>
      </div>
    </div>

    <section class="section">
      <div class="word" v-for="(word, key) in words" :key="key">
        <div class="word__img" :style="{backgroundImage: `url(${word.image?.lg || word.image?.original})`}"/>
        <div class="word__content">
          <h2 class="section__title">{{word.name}}</h2>
          <div v-html="word.content" class="word__text"></div>
          <div class="word__logo" :style="{backgroundImage: `url(${word.logo?.md || word.logo?.original})`}"/>
        </div>
      </div>
    </section>

    <PageBuilder :sections="$page.sections"/>
  </template>

  <template v-else>
    <div style="min-height: 100vh"/>
  </template>

</template>

<script>

import PageBuilder from "@/components/PageBuilder.vue";
import {setMeta} from "@/helpers/metaHelpers";

export default {
  name: 'Words',
  beetPage: 'dignitaire',
  components: { PageBuilder },
  beforeMount() {
    if(this.$page) setMeta(this.$page.seo)
  },
  computed: {
    words() {
      if(!this.$beet.isReady) return []
      const obj = [...Object.values(this.$beet.dignitaries)]
      return obj.sort((a,b) => {
        if(a.order < b.order) return -1
        if(a.order > b.order) return 1
        return 0
      })
    }
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    }
  }
}
</script>
