<template>
  <template v-if="$page">
    <section class="header-home">
      <div class="header-home__img"
           :style="{backgroundImage: `url(${$page.header.image.lg || $page.header.image.original})`}">
        <div class="header-home__img-fade"/>
      </div>
      <div class="header-home__block-title">
        <div>
          <h1 class="header-home__title" v-scroll-reveal>{{ $page.header.title }}</h1>
          <p class="header-home__subtitle" v-scroll-reveal>{{ $page.header.subtitle }}</p>
          <button @click="openLink($page.header.btnProgrammationLien)" class="button" v-scroll-reveal>
            {{ $page.header.btnProgrammation }}
          </button>
        </div>

        <div class="header-home__block-medaillon" v-scroll-reveal v-if="false">
          <div>
            <p class="header-home__block-medaillon__title">{{ $page.medallion.title }}</p>
            <div class="header-home__block-medaillon__link" @click="$router.push(getLocalRoute({name:'tickets'}))">
              {{ $page.medallion.subtitle }}
              <Icon id="link" class="header-home__block-medaillon__icon"/>
            </div>
          </div>
          <div class="header-home__block-medaillon__medaillon">
            <img src="/img/medaillon_droit.png"/>
          </div>
        </div>
      </div>
    </section>

    <PageBuilder :sections="$page.sections"/>

  </template>

  <template v-else>
    <div style="height: 100vh"/>
  </template>
</template>

<script>

import {setMeta} from '@/helpers/metaHelpers'
import PageBuilder from '@/components/PageBuilder.vue'

export default {
  name: 'Home',
  components: {PageBuilder},
  beetPage: 'home',
  data() {
    return {
      video: false
    }
  },
  beforeMount() {
    const seo = this.$page.seo
    seo.image = this.$page.seo.image.original
    if (this.$page) setMeta(this.$page.seo)
  },
  methods: {
    openLink(link) {
      link.includes('http')
          ? window.open(link, '_blank')
          : this.$router.push(link)
    }
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    }
  }
}
</script>
