<template>
  <template v-if="$page">
    <div class="new-header">
      <div class="new-header__image" :style="{backgroundImage : `url(${ $page.header.image.lg || $page.header.image.original  })`}"/>

      <div class="new-header__content">
        <h1 class="header__title" v-scroll-reveal>{{ $page.header.title }}</h1>
        <p class="header__text" v-scroll-reveal>{{ $page.header.subtitle }}</p>
      </div>
    </div>

    <PageBuilder :sections="$page.sections"/>
    <!--
    <section class="section">
      <h2 class="section__title" v-scroll-reveal>{{ travel.name }}</h2>
      <p class="section__text" v-scroll-reveal>{{ travel.description }}</p>
      <div class="section__buttons" v-scroll-reveal>
        <button class="button" @click="openMap()">{{ 'label' }}</button>
      </div>

      <div class="info-cards">
        <CardLink v-for="(item, key) in travel.items" :key="key" v-scroll-reveal="{delay: key*50}"
                  :img="item.image.md || item.image.original"
                  :link-url="item.reservationLink"
                  :link-label="'label'"
        />
      </div>
    </section>

    <SectionLinks
        v-if="arrival"
        :title="arrival.name"
        :text="arrival.description"
        :button-label="$t('sanitary')"
        :button-action="covid"
        :links="arrival.items"
    />

    <section class="section">
      <h2 class="section__title" v-scroll-reveal>{{sleep.name}}</h2>
      <p class="section__text -small" v-scroll-reveal>{{sleep.description}}</p>

      <div class="info-cards">
        <CardLink v-for="(item, key) in sleep.items" :key="key" v-scroll-reveal="{delay: key*50}"
        :img="item.image.md || item.image.lg || item.image.original"
        :link-url="item.reservationLink"
        :link-label="'Visitez le site web'"/>
      </div>
    </section>

    <section class="section">
      <h2 class="section__title" v-scroll-reveal>{{eat.name}}</h2>
      <p class="section__text -small" v-scroll-reveal>{{eat.description}}</p>

      <div class="info-cards">
        <CardLink v-for="(item, key) in eat.items" :key="key" v-scroll-reveal="{delay: key*50}"
                  :img="item.image.md || item.image.lg || item.image.original"
                  :link-url="item.reservationLink"
                  :link-label="'Visitez le site web'"/>
      </div>
    </section>

    <SectionLinks
        light
        :title="culture.name"
        :text="culture.description"
        :links="culture.items"
    />
    -->
  </template>

  <template v-else>
    <div style="min-height: 100vh"/>
  </template>

</template>

<script>

import {setMeta} from "@/helpers/metaHelpers";
import CardLink from "@/components/CardLink";
import SectionLinks from "@/components/SectionLinks";
import PageBuilder from "@/components/PageBuilder.vue";

export default {
  name: 'Info',
  components: {PageBuilder, SectionLinks, CardLink},
  beetPage: 'info',
  data() {
    return {
      faqOpen: null
    }
  },
  beforeMount() {
    if(this.$page) setMeta(this.$page.seo)
  },
  computed: {
    types() {
      if(this.$beet.todoTypes) return Object.values(this.$beet.todoTypes)
      return null
    },
    todos() {
      if(this.$beet.todos) return Object.values(this.$beet.todos)
      return []
    },
    travel() {
      if(!this.types) return {}
      const type = this.types.find(({id}) => id === 5)
      type.items = this.todos.filter(({todoTypeId}) => todoTypeId === type.id)
      return type
    },
    arrival() {
      if(!this.types) return {}
      const type = this.types.find(({id}) => id === 4)
      type.items = this.todos.filter(({todoTypeId}) => todoTypeId === type.id)
      type.items.forEach(item => {
        item.label = this.$t('visit')
        item.url = item.reservationLink
      })
      return type
    },
    culture() {
      if(!this.types) return {}
      const type = this.types.find(({id}) => id === 3)
      type.items = this.todos.filter(({todoTypeId}) => todoTypeId === type.id)
      type.items.forEach(item => {
        item.label = this.$t('visit')
        item.url = item.reservationLink
      })
      return type
    },
    sleep() {
      if(!this.types) return {}
      const type = this.types.find(({id}) => id === 2)
      type.items = this.todos.filter(({todoTypeId}) => todoTypeId === type.id)
      type.items.forEach(item => {
        item.label = this.$t('visit')
        item.url = item.reservationLink
      })
      return type

    },
    eat() {
      if(!this.types) return {}
      const type = this.types.find(({id}) => id === 1)
      type.items = this.todos.filter(({todoTypeId}) => todoTypeId === type.id)
      type.items.forEach(item => {
        item.label = this.$t('visit')
        item.url = item.reservationLink
      })
      return type

    }
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    }
  },
  methods: {
    openMap() {
      window.open(`https://www.google.com/maps/place/60+Rue+D'Auteuil,+Qu%C3%A9bec,+QC+G1R+4C4`, "_blank")
    },
    covid() {
      const url = this.$i18n.locale === 'fr' ? 'https://www.quebec-cite.com/fr/a-propos/coronavirus' : 'https://www.quebec-cite.com/en/about/coronavirus'
      window.open(url, '_blank')
    }
  }
}
</script>
