<template>
  <section class="section">
    <div class="giant-title">
      <h2 class="section__title -big" v-scroll-reveal>{{$t('giants')}}</h2>
      <div v-scroll-reveal class="giant__arrows">
        <button @click="prev" :class="{'-disabled': !canPrev}" class="giant__arrows-left">
          <Icon id="arrow" style="transform: rotate(180deg); margin-right: 1rem"/>
        </button>
        <button @click="next" :class="{'-disabled': !canNext}">
          <Icon id="arrow"/>
        </button>
      </div>
    </div>

    <div class="giant-row" v-if="items.length">
      <div class="giant" v-for="(item, key) in currentItems" :key="key" v-scroll-reveal="{delay: key*50}">
        <div class="giant__img" :style="{backgroundImage: `url(${item.image.lg || item.image.original})`}"/>

        <div class="giant__title">{{item.name}}</div>
        <div class="giant__text">{{more === key ? item.description : `${item.description.substr(0, 130)}...`}}</div>
        <button class="giant__more" @click="more === key ? more = null : more = key">
          {{ moreDetail(key)}} <Icon :id="more === key ? 'minus' : 'plus'"/>
        </button>
      </div>
    </div>

    <div class="giant__seemore" v-if="canNext" @click="next()">
      {{ $t('seeMore') }} {{ $t('giant') }} <Icon id="plus" style="font-size: 0.6rem; margin-left: 0.5rem"/>
    </div>
  </section>
</template>

<script>
export default {
name: "SectionGiants",
  data() {
   return {
     page: 0,
     more: null
   }
  },
  computed: {
    currentItems() {
      return [this.items[this.page], this.items[this.page + 1], this.items[this.page + 2]]
    },
    items() {
      if(this.$beet.giants) {
        const items = Object.values(this.$beet.giants)
        if(items) {
          return items.sort((a,b) => {
            if(a.order < b.order) return -1
            if(a.order > b.order) return 1
            return 0
          })
        }
      }
      return []
    },
    canNext() {
      return this.page !== (this.items.length - 3)
    },
    canPrev() {
      return this.page !== 0
    }
  },
  methods: {
    moreDetail(key) {
      return `${this.more === key ? this.$i18n.locale === 'fr' ? 'Moins' : 'Less' : this.$i18n.locale === 'fr' ? 'Plus' : 'More'}` +
          ` ${this.$i18n.locale === 'fr' ? 'de détails' : 'details'}`
    },
    next() {
      if(this.canNext) {
        this.more = null
        this.page++
      }
    },
    prev() {
      if(this.canPrev) {
        this.more = null
        this.page--
      }
    }
  }
}
</script>
