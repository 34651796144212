<template>
  <section class="section" v-if="orientation === 'horizontal'" :class="{'-light' : backgroundColor !== 'primary', '-white': backgroundColor === 'white'}">
    <h2 class="section__title" v-scroll-reveal>{{ title }}</h2>
    <p class="section__text" v-scroll-reveal>{{ description }}</p>
    <button v-if="btnText" @click="openLink(btnLink)" class="button links__button" v-scroll-reveal>
      {{btnText}}
    </button>

    <div class="info-cards">
      <CardLink v-for="(item, key) in links" :key="key" v-scroll-reveal="{delay: key*50}"
                :img="item.image?.md || item.image?.original"
                :link-url="item.url || item.reservationLink"
                :link-label="item.label"
      />
    </div>
  </section>


  <section v-else class="links" :class="{'-light' : backgroundColor !== 'primary', '-white': backgroundColor === 'white'}">
    <div class="links__section">
      <h3 class="section__title" v-scroll-reveal>{{title}}</h3>
      <div v-html="description" class="links__text" v-scroll-reveal/>
      <button v-if="btnText" class="button links__button" :class="{'-light' : !light}" v-scroll-reveal @click="openLink(btnLink)">
        {{btnText}}
      </button>
    </div>

    <div class="links__section links__flex" v-if="links.length">
      <a :href="link.url || link.reservationLink" target="_blank"  v-for="(link, key) in links" :key="key" class="links__card" v-scroll-reveal="{delay: key*50}">
        <div class="links__card-img" :style="{backgroundImage: `url(${link.image?.md || link.image?.lg || link.image?.original})`}"/>
        <div class="button -transparent">
          {{link.name}}
          <Icon id="link"/>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import CardLink from "@/components/CardLink.vue";

export default {
name: "SectionLinks",
  components: {CardLink},
  props: {
    orientation: {
      type: String,
      default: 'right'
    },
    backgroundColor: {
      type: String
    },
    light: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    btnLink: {
      type: String
    },
    btnText: {
      type: String
    },
    todoType: {
      type: String
    }
  },
  computed: {
    types() {
      if(this.$beet.todoTypes) return Object.values(this.$beet.todoTypes)
      return null
    },
    todos() {
      const todos = Object.values(this.$beet.todos)
      if(todos) return todos
      return []
    },
    links() {
      if(!this.types) return {}
      const localTypeId = Number(this.todoType)
      return this.todos.filter(({todoTypeId}) => todoTypeId === localTypeId)
    }
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank')
    }
  }
}
</script>
