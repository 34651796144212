<template>
  <section class="cta" :class="{'-light' : light || backgroundColor === 'secondary', '-white': backgroundColor === 'white', 'bg-blue': isBlue}" :style="bgImage">
    <div class="cta__title" v-scroll-reveal>{{ title }}</div>
    <div class="cta__separator" v-scroll-reveal>
      <div class="cta__line"/>
      <Icon :id="icon" class="cta__icon"/>
      <div class="cta__line"/>
    </div>
    <div class="cta__text" v-html="description" v-scroll-reveal/>

    <button class="button cta__button" :class="{'-light' : backgroundColor === 'primary' || backgroundColor !== 'white' }" v-if="btnText" @click="buttonAction" v-scroll-reveal>
      {{btnText}}
    </button>
  </section>
</template>

<script>
export default {
name: "SectionCta",
  props: {
    backgroundColor: {
      type: String,
      default: 'primary'
    },
    light: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    icon: {
      type: String,
      default: 'birds'
    },
    description: {
      type: String
    },
    btnText: {
      type: String
    },
    btnLink: {
      type: String
    },
    buttonAction: {
      type: Function,
      default: function() { window.open(this.btnLink, '_blank')}
    },
    backgroundImage: {

    },
    withImg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isBlue() {
      return this.backgroundColor === 'blue'
    },
    bgImage() {
      if(this.backgroundColor === 'image') return {backgroundImage: `url(${this.backgroundImage?.lg ? this.backgroundImage.lg || this.backgroundImage.original : ''})`}
      return {}
    }
  }
}
</script>
