<template>
  <div class="media-press" v-if="press">
    <LangRouterLink :to="{name: 'media'}" class="row header__back">
      <icon id="back"/> {{ $t('mediaBack') }}
    </LangRouterLink>
    <br/>
    <h1 class="section__title">{{press.name}}</h1>

    <div class="media-item__date">
      <Icon id="date"/> {{getDate(press.publishedAt)}}
    </div>

    <div v-html="press.content" class="raw-html"/>
  </div>

  <div v-else style="min-height: 100vh"></div>
</template>

<script>

import {setMeta} from "@/helpers/metaHelpers";

export default {
  name: 'MediaPress',
  computed: {
    press() {
      if(this.$beet.pressReleases) {
        const arr = Object.values(this.$beet.pressReleases)
        return arr.find(({slugs}) => {
          return (slugs.fr === this.$route.params.slug || slugs.en === this.$route.params.slug)
        })
      }
      return null
    }
  },
  watch: {
    '$page'(to) {
      setMeta(to.seo)
    }
  },
  methods: {
    getDate(date) {
      const day = new Date(date).toLocaleDateString(`${this.$i18n.locale}-CA`, {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      })
      return day
    }
  },
}
</script>
